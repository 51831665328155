<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Policy: {{(quoteDetails)?quoteDetails.amlin_policy_reference:''}}
                <span
                    class="badge badge-danger text-white text-capitalize">{{(quoteDetails)?quoteDetails.status:''}}</span>
            </h3>
        </div>
        <div class="col-md-6 text-right" *ngIf="quoteDetails && quoteDetails.status != 'cancelled'">
            <div class="align-items-center justify-content-between float-right">
                <ul class="navbar-nav navbar-nav-right mr-3">
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle p-0 text-white" href="#" data-toggle="dropdown"
                            id="profileDropdown">
                            Actions
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                            aria-labelledby="profileDropdown">
                            <a (click)="policyActions(3)" class="dropdown-item">
                                <i class="mdi mdi-account-convert text-primary"></i>
                                Convert to MTA
                            </a>
                            <a (click)="cancelPolicy(cancelModal)" class="dropdown-item">
                                <i class="mdi mdi-delete-variant text-primary"></i>
                                Cancel
                            </a>
                            <!-- <a (click)="convert_to_mta(content,5)" class="dropdown-item" *ngIf="quoteData.type == 2">
                                <i class="mdi mdi-delete text-primary"></i>
                                Lapse 
                            </a> 
                            <a (click)="convert_to_mta(content,6)" class="dropdown-item" 
                                *ngIf="(quoteData.type == 1 || quoteData.type == 3) && is_NTU">
                                <i class="mdi mdi-arrange-bring-forward"></i>
                                NTU 
                            </a> -->

                        </div>
                    </li>
                </ul>
                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    data-toggle="horizontal-menu-toggle">
                    <span class="mdi mdi-menu"></span>
                </button>
            </div>
        </div>
    </div>
</div>
<mat-tab-group class="float-left w-100">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-am-overview [quoteDetails]="quoteDetails"></app-am-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-am-full-details [quoteDetails]="quoteDetails"></app-am-full-details>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Drivers
        </ng-template>
        <div class="card-body">
            <app-am-drivers-view [quoteDetails]="quoteDetails"></app-am-drivers-view>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Financials
        </ng-template>
        <div class="card-body">
            <app-am-financials [quoteDetails]="quoteDetails"></app-am-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-am-documents [quote]="quoteDetails"></app-am-documents>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000"
                title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-quote-notes [quote]="quoteDetails"
                (hasImportantNotes)="onhasImportantNotesChange($event)"></app-quote-notes>
        </div>
    </mat-tab>
</mat-tab-group>
<ng-template #issueQuoteModal let-modal>
    <div class="modal-body">
        <p>Do you want to issue the quote?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="modal.close()">No</button>
        <button type="button" class="btn btn-success btn-sm" (click)="issueQuote()">Yes</button>
    </div>
</ng-template>
<ng-template #cancelModal let-modal>
    <div class="modal-header">
        <h4>Cancel Policy</h4>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger" *ngIf="errorMsg">{{errorMsg}}</div>
        <div class="row">
            <div class="col-md-5">Cancellation date</div>
            <div class="col-md-7">
                <div class="form-group w-100">
                    <input type="text" readonly required name="cancellation_date" placeholder="Cancellation Date"
                        class="form-control form-control-sm datepicker" matInput #cancellationDate="ngModel"
                        [matDatepicker]="myDatepicker" [(ngModel)]="cancellation_date" [min]="minCacellationDate" [max]="maxCacellationDate"
                        [ngClass]="{ 'is-invalid': submitted && !cancellation_date }"
                        style="width: 86%;" (dateChange)="getReturnPremium()">
                    <mat-datepicker-toggle [for]="myDatepicker" class="modalDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker></mat-datepicker>
                    <div *ngIf="submitted && !cancellation_date" class="invalid-feedback">
                        Cancellation date is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">Cancellation reason (for internal records)</div>
            <div class="col-md-7">
                <div class="form-group">
                    <mat-form-field class="w-100">
                        <mat-label>Select</mat-label>
                        <mat-select [(ngModel)]="cancellation_reason" class="form-control form-control-sm"
                            name="cancellation_reason" (selectionChange)="getReturnPremium()"
                            [ngClass]="{ 'is-invalid': submitted && !cancellation_reason }"
                            #cancellationReason="ngModel" required>
                            <mat-option *ngFor="let c of staticData.cancellation_options" [value]="c">
                                {{ c }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="submitted && !cancellation_reason" class="invalid-feedback">
                            Cancellation reason is required.
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">Refund amount (inc. IPT)</div>
            <div class="col-md-7">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" appDecimalNumbers name="refundAamount" placeholder="Refund Amount"
                            class="form-control form-control-sm" autocomplete="off"
                            [(ngModel)]="refund_amount" #refundAamount="ngModel" required
                            [ngClass]="{ 'is-invalid': submitted && !refund_amount }">                            
                    </div>
                    <div *ngIf="submitted && !refund_amount" class="invalid-feedback">
                        Amount is required.
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="modal.close()">Close</button>
        <button type="button" class="btn btn-success btn-sm" (click)="onSubmitCancelPolicy()">Cancel Policy</button>
    </div>
</ng-template>