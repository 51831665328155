import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class BordereauService {

  constructor(private sharedService: SharedService) { }


  // Save Indicative Quote
  download(data){    
    return this.sharedService.postDownload('bordereau/download', data);
  }

  // Export live report
  live(){    
    return this.sharedService.postDownload('bordereau/live', '');
  }

  // Get Products List
  getProducts(){    
    return this.sharedService.getAPI('products');
  }

  // Export lapse report
  lapse(data){    
    return this.sharedService.postDownload('bordereau/lapse', data);
  }
}

