<div class="row mb-3">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-6"><label>Premium(Exc. IPT):</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="ipt" [value]="(premium?gross_premium - (premium.ipt + premium.breakdown_ipt):0) | number:'1.2-2'" class="form-control form-control-sm" readonly>
                </div>
            </div>
        </div>  
        <div class="row mt-3"> 
            <div class="col-md-6"><label>IPT:</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="ipt" [value]="(premium?(premium.ipt + premium.breakdown_ipt):0) | number:'1.2-2'" class="form-control form-control-sm" readonly>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6"><label>Gross Premium (Inc. IPT):</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="gross" [value]="gross_premium | number:'1.2-2'" class="form-control form-control-sm"
                        readonly>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6"><label>Peliwica Fee:</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="peliwica_fee" [value]="peliwica_fee | number:'1.2-2'" class="form-control form-control-sm"
                        readonly>
                </div>
            </div>
        </div>
       
        <!-- <div class="row mt-3">
            <div class="col-md-6"><label>Commission:</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="commission" [value]="commission | number:'1.2-2'" class="form-control form-control-sm"
                        readonly>
                </div>
            </div>
        </div> -->
        <div class="row mt-3">
            <div class="col-md-6"><label>Total Amount(Inc. IPT):</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="total_amount" [value]="total_amount | number:'1.2-2'" class="form-control form-control-sm"
                        readonly>
                </div>
            </div>
        </div>
        <!-- <p *ngIf="vehicle_premiums_key">
            <u>Rate per Vehicle</u><br>
        </p>
        <div class="row" *ngIf="vehicle_premiums_key">
            <div class="col-6">
                <label>{{ vehicle_premiums_key }}</label>
            </div>
            <div class="col-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                    </div>
                    <input type="text" name="vehicle_premiums_value" [value]="vehicle_premiums_value" class="form-control form-control-sm"
                        readonly>
                </div>
            </div>
        </div> -->
    </div>
</div>