<h4><u>Quote Details</u></h4>
<div class="row">
    <div class="col-md-7">
        <div class="row mb-3">
            <div class="col-md-6"><label>Cover From:</label></div>
            <div class="col-md-6">
                <input type="text" name="cover_from" [value]="quoteApplicationData.inception_date | date:'dd-MM-yyyy'"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Cover To:</label></div>
            <div class="col-md-6">
                <input type="text" name="cover_to" [value]="CoverTo | date:'dd-MM-yyyy'"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Product:</label></div>
            <div class="col-md-6">
                <input type="text" name="product" value="Horsebox" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Broker:</label></div>
            <div class="col-md-6">
                <input type="text" name="broker" value="Peliwica Limited" class="form-control form-control-sm" readonly>
            </div>
        </div>
    </div>
</div>

<h4><u>Proposer Details</u></h4>
<div class="row">
    <div class="col-md-7">
        <div class="row mb-3">
            <div class="col-md-6"><label>Customer type:</label></div>
            <div class="col-md-6">
                <input type="text" name="proposer_type"
                    [value]="quoteApplicationData.proposer.proposer_type | titlecase"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <!--- Company Details   -->
        <div
            *ngIf="quoteApplicationData.proposer.proposer_type == 'company' || quoteApplicationData.proposer.proposer_type == 'joint'">
            <div class="row mb-3">
                <div class="col-md-6"><label>Is this client a Micro-enterprise?</label></div>
                <div class="col-md-6">
                    <mat-radio-group class="row" aria-label="Select an option" name="micro_enterprise" disabled>
                        <div class="col-md-6">
                            <div class="quote_radio">
                                <mat-radio-button value="1"
                                    [checked]="quoteApplicationData.proposer.micro_enterprise">Yes</mat-radio-button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="quote_radio">
                                <mat-radio-button value="2"
                                    [checked]="!quoteApplicationData.proposer.micro_enterprise">No</mat-radio-button>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>{{(quoteApplicationData.proposer.proposer_type == 'joint')?'Names':'Company
                        Name'}}:</label></div>
                <div class="col-md-6">
                    <input type="text" name="company_name"
                        [value]="quoteApplicationData.proposer.company_name | titlecase"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>Trading Name:</label></div>
                <div class="col-md-6">
                    <input type="text" name="trading_name"
                        [value]="quoteApplicationData.proposer.trading_name | titlecase"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3" *ngIf="quoteApplicationData.proposer.proposer_type == 'company'">
                <div class="col-md-6"><label>Year Company Established:</label></div>
                <div class="col-md-6">
                    <input type="text" name="year" [value]="quoteApplicationData.proposer.year"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>Rating Type:</label></div>
                <div class="col-md-6">
                    <input type="text" name="year" [value]="quoteApplicationData.rating_type | uppercase"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
        </div>

        <!--- Individual Details -->
        <div *ngIf="quoteApplicationData.proposer.proposer_type == 'individual'">
            <div class="row mb-3">
                <div class="col-md-6"><label>Title:</label></div>
                <div class="col-md-6">
                    <input type="text" name="title" [value]="quoteApplicationData.proposer.title | titlecase"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>First Name:</label></div>
                <div class="col-md-6">
                    <input type="text" name="first_name" [value]="quoteApplicationData.proposer.first_name | titlecase"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>Last Name:</label></div>
                <div class="col-md-6">
                    <input type="text" name="last_name" [value]="quoteApplicationData.proposer.last_name | titlecase"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>Date Of Birth:</label></div>
                <div class="col-md-6">
                    <input type="text" name="dob" [value]="quoteApplicationData.proposer.dob"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>Type of licence:</label></div>
                <div class="col-md-6">
                    <input type="text" name="licence_type" [value]="quoteApplicationData.proposer.licence_type"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6"><label>Length licence held:</label></div>
                <div class="col-md-6">
                    <input type="text" name="licence_type"
                        [value]="quoteApplicationData.proposer.licence_length | date:'dd-MM-yyyy'"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
        </div>

        <!--  Address Details -->
        <h6><u>Address Details</u></h6>
        <div class="row mb-3">
            <div class="col-md-6"><label>Address Line 1:</label></div>
            <div class="col-md-6">
                <input type="text" name="line1" [value]="quoteApplicationData.proposer.address.line1"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Address Line 2:</label></div>
            <div class="col-md-6">
                <input type="text" name="line2" [value]="quoteApplicationData.proposer.address.line2"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Address Line 3:</label></div>
            <div class="col-md-6">
                <input type="text" name="line3" [value]="quoteApplicationData.proposer.address.line3"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>County:</label></div>
            <div class="col-md-6">
                <input type="text" name="county" [value]="quoteApplicationData.proposer.address.county"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Postcode:</label></div>
            <div class="col-md-6">
                <input type="text" name="postcode" [value]="quoteApplicationData.proposer.address.postcode"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
    </div>
</div>

<div *ngIf="quoteApplicationData.proposer.convictions && quoteApplicationData.proposer.convictions.length > 0">
    <h4><u>Convictions</u></h4>
    <div class="row">
        <div class="col-md-7">
            <div *ngFor="let conv of quoteApplicationData.proposer.convictions; let i = index;">
                <h6><u>Conviction {{ i+1 }}</u></h6>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Conviction code:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="code_{{i}}" [value]="conv.code" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Conviction Date:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="date_{{i}}" [value]="conv.date | date:'dd-MM-yyyy'" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Number of points:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="points_{{i}}" [value]="conv.points" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Number of months banned:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="points_{{i}}" [value]="conv.banned" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Fine:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="fine_{{i}}" [value]="conv.fine | number: '1.2-2'" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="quoteApplicationData.proposer.claims && quoteApplicationData.proposer.claims.length > 0">
    <h4><u>Claims</u></h4>
    <div class="row">
        <div class="col-md-7">
            <div *ngFor="let clm of quoteApplicationData.proposer.claims; let i = index;">
                <h6><u>Claims {{ i+1 }}</u></h6>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Date of claim:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="claim_date_{{i}}" [value]="clm.date | date:'dd-MM-yyyy'" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Type of incident:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="incident_type_{{i}}" [value]="clm.type" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>AD claim cost:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="ad_cost_{{i}}" [value]="clm.ad_cost | number: '1.2-2'" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>TP claim cost:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="tp_cost_{{i}}" [value]="clm.tp_cost | number: '1.2-2'" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Total claim cost:</label></div>
                    <div class="col-md-6">
                        <input type="text" name="total_{{i}}" [value]="(clm.ad_cost + clm.tp_cost) | number: '1.2-2'" class="form-control form-control-sm"
                            readonly>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Was the driver at fault?:</label></div>
                    <div class="col-md-6">
                        <mat-radio-group class="row" aria-label="Select an option" disabled name="fault_{{i}}"> 
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button value="true" [checked]="clm.fault">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button value="false" [checked]="!clm.fault">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6"><label>Is the claim still open?:</label></div>
                    <div class="col-md-6">
                        <mat-radio-group class="row" aria-label="Select an option" disabled name="open_{{i}}"> 
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button value="true" [checked]="clm.open">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button value="false" [checked]="!clm.open">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>