import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmlineService } from '../amline.service';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css', '../../quote.component.css']
})
export class VehiclesComponent implements OnInit {
  staticAPIData: any;
  @Input() staticData;
  @Input() modelObj;
  @Input() apiModelObj;
  frmFields: any;
  submitted = false;
  @Input() authDetails;
  @Output() modelObjUpdated = new EventEmitter<Amline>();

  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdr: ChangeDetectorRef, private as: AmlineService,
    private route: ActivatedRoute, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.frmFields = this.fb.group({
      vehicles: this.fb.array([
        this.initVehicles()
      ]),
      drivers: this.fb.array([]),
    });
  }

  ngDoCheck() {
    this.staticAPIData = this.staticData;

  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  parentModelObj;
  drivingRestrictions: any = [];
  rating_type: string = '';
  ngOnChanges(changes: SimpleChanges) {
    this.parentModelObj = this.modelObj;



    if (this.apiModelObj) {
      this.modelObj = this.apiModelObj.amlin_data.data.attributes.application;
      this.modelObj.id = this.apiModelObj.amlin_data.data.id;
      this.modelObj.reference = this.apiModelObj.amlin_data.data.attributes.reference;
      this.modelObj.status = this.apiModelObj.amlin_data.data.attributes.status;
      this.modelObj.sequence_number = this.apiModelObj.sequence_number;

      this.rating_type = this.apiModelObj.amlin_data.data.attributes.application.rating_type;
    }

    if (this.staticData && this.modelObj && this.modelObj.proposer) {
      this.drivingRestrictions = (this.modelObj.proposer.proposer_type == 'company') ? this.staticData.driving_restrictions_commercial : this.staticData.driving_restrictions_personal;
    }
    this.patchValues();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.frmFields.controls;
  }

  get vehicles(): FormArray {
    return this.frmFields.get("vehicles") as FormArray
  }

  get driversFullList(): FormArray {
    return this.frmFields.get("drivers") as FormArray
  }

  patchValues() {
    if (this.modelObj) {
      this.drivers_list = this.modelObj.drivers;
    }
    if (this.frmFields && this.modelObj && this.modelObj.vehicles && this.modelObj.vehicles.length > 0) {

      this.patchVehicles();


      if (this.modelObj.drivers && this.modelObj.drivers.length > 0) {
        this.driversFullList.clear();
        this.modelObj.drivers.forEach((res) => {
          let dataCtrls = this.fb.group(res);
          this.driversFullList.push(dataCtrls);
        })
      }



      this.modelObj.vehicles.forEach((res, i) => {
        this.activateDriversSection(res.driving_restriction, i);
        // if(this.rating_type == 'ncd') {
        //   this.vehicles.at(i).get('ncd').enable();
        // } else {
        //   this.vehicles.at(i).get('ncd').disable();
        // }





        // Extended Use
        let driver_equine = res.extended_use.driver_equine;
        let driver_non_equine = res.extended_use.driver_non_equine;
        let livestock_carriage = res.extended_use.livestock_carriage;
        let proposer_equine = res.extended_use.proposer_equine;
        let proposer_non_equine = res.extended_use.proposer_non_equine;
        // if (!proposer_non_equine) {
        //   this.vehicles.at(i).get('non_equine_trade').disable();
        // }

        // let is_extended_use = false;
        // if (!driver_equine && !driver_non_equine && !livestock_carriage && !proposer_equine && !proposer_non_equine) {
        //   is_extended_use = false;
        // } else {
        //   is_extended_use = true;
        // }
        // this.vehicles.controls[i].get('is_extended_use').setValue(is_extended_use);

        // Equine Trade Drivers Binding
        if (driver_equine) {
          this.equineDriverControls(i).clear();
          res.equine_trade_drivers.forEach((d, idx) => {
            let drivers = this.modelObj.equine_trade_drivers.filter(x => x.id == d);
            if (drivers.length > 0) {
              drivers.forEach((dr) => {
                let dataCtrls = this.fb.group(dr);
                this.equineDriverControls(i).push(dataCtrls);
              })
            }
          })
        }

        // Non-Equine Trade Drivers Binding
        if (driver_non_equine) {
          this.nonEquineDriverControls(i).clear();
          res.non_equine_trade_drivers.forEach((d, idx) => {
            let drivers = this.modelObj.non_equine_trade_drivers.filter(x => x.id == d);
            if (drivers.length > 0) {
              drivers.forEach((dr) => {
                let dataCtrls = this.fb.group(dr);
                this.nonEquineDriverControls(i).push(dataCtrls);
              })
            }
          })
        }


        // Owner    
        let is_owner = (res.owner) ? false : true;
        this.enableOwner(is_owner, i);
        // let is_owner = (res.owner) ? false : true;
        // this.enableOwnerField(is_owner, i);
        // this.vehicles.controls[i].get('is_registered_owner').setValue(is_owner);
        //this.enableOwner(is_owner, i);

        // Postcode if kept overnight    

        // this.enablePostcode(res.postcode, i);

        // this.enablePostcodeIfKeptOvernight(postcode, i);
        // this.vehicles.controls[i].get('is_kept_overnight').setValue(postcode);
        //this.enablePostcode(postcode, i);

      })



    }
  }

  patchVehicles() {
    if (this.modelObj.vehicles.length > 0) {
      this.vehicles.clear();
      this.modelObj.vehicles.forEach((res, i) => {

        let vehicles = this.fb.group({
          registration_number: [res.registration_number],
          make: [res.make],
          model: [res.model],
          year: [res.year],
          weight: [res.weight],
          value: [res.value],
          cover_type: [res.cover_type],
          driving_restriction: [res.driving_restriction],
          green_card: [res.green_card],
          mileage: [res.mileage],
          tracker: [res.tracker],
          ncd: [res.ncd],
          is_extended_use: [this.extendeduseButtons(res, i)],
          extended_use: this.fb.group({
            proposer_equine: [res.extended_use.proposer_equine],
            proposer_non_equine: [res.extended_use.proposer_non_equine],
            driver_equine: [res.extended_use.driver_equine],
            driver_non_equine: [res.extended_use.driver_non_equine],
            livestock_carriage: [res.extended_use.livestock_carriage],
          }),
          owner: res.owner,
          owner_name: [''],
          is_registered_owner: (res.owner) ? false : true,
          is_kept_overnight: [(res.postcode) ? false : true],
          postcode: res.postcode,
          drivers: this.fb.array([]),
          non_equine_trade: [''],
          equine_trade_drivers: this.fb.array([]),
          non_equine_trade_drivers: this.fb.array([]),
        })
        if (!res.registration_number) {
          vehicles.get('is_registered_owner').setValue(2);
          vehicles.get('is_kept_overnight').setValue(2);
        }
        this.vehicles.push(vehicles);

        this.patchDrivers(res, i);

      })
    }
  }

  /*
  * Get Vehicle Details By Registration Number
  */
  onRegistrationNumberChange(event, index) {
    this.vehicles.controls[index].patchValue({
      "make": '',
      "model": '',
      "value": '',
      "weight": '',
      "year": '',
    });
    this.as.getVehicleDetails(event.target.value, this.authDetails)
      .subscribe(
        (res: any) => {
          if (res.meta) {
            this.vehicles.controls[index].patchValue({
              "make": res.meta.make,
              "model": res.meta.model,
              "value": res.meta.value,
              "weight": res.meta.weight,
              "year": res.meta.year,
            });
          }
        }, err => {
          if (err.error && err.error.errors) {
            this._snackBar.open('Vehicle not found.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });

          }
        },);

  }

  extendeduseButtons(res, index) {
    if (res && res.extended_use) {
      let driver_equine = res.extended_use.driver_equine;
      let driver_non_equine = res.extended_use.driver_non_equine;
      let livestock_carriage = res.extended_use.livestock_carriage;
      let proposer_equine = res.extended_use.proposer_equine;
      let proposer_non_equine = res.extended_use.proposer_non_equine;
      let is_extended_use = null;
      if (this.modelObj.id) {
        if (driver_equine == false && driver_non_equine == false && livestock_carriage == false && proposer_equine == false && proposer_non_equine == false) {
          is_extended_use = false;
        } else {
          is_extended_use = true;
        }
      }
      return is_extended_use;
    }
  }


  patchDrivers(res, i) {
    // Drivers Binding
    if (this.modelObj.drivers.length > 0) {
      this.driverControls(i).clear();
      this.modelObj.drivers.forEach((res1) => {
        let data = this.fb.group({ 'id': res1.id })
        this.driverControls(i).push(data);
      })
    }
  }

  /*
 * HELP TEXT POPUP
 */
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
* Horseboxes
*/
  initVehicles() {
    return this.fb.group({
      registration_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      year: ['', Validators.required],
      weight: ['', Validators.required],
      value: ['', Validators.required],
      cover_type: ['', Validators.required],
      driving_restriction: ['', Validators.required],
      is_extended_use: ['', Validators.required],
      extended_use: this.extendedUse(),
      non_equine_trade: ['', Validators.required],
      drivers: this.fb.array([]),
      equine_trade_drivers: this.fb.array([]),
      non_equine_trade_drivers: this.fb.array([]),
      green_card: ['', Validators.required],
      mileage: ['', Validators.required],
      is_registered_owner: ['', Validators.required],
      owner: ['', Validators.required],
      owner_name: [''],
      is_kept_overnight: ['', Validators.required],
      postcode: ['', Validators.required],
      tracker: ['', Validators.required],
      ncd: [''],
    })
  }

  extendedUse() {
    return this.fb.group({
      proposer_equine: [''],
      proposer_non_equine: [''],
      driver_equine: [''],
      driver_non_equine: [''],
      livestock_carriage: [''],
    })
  }

  get extendedUseControls() {
    return ((this.initVehicles().get('extended_use') as FormGroup).controls)
  }

  onExtendedUseChange(event, vehicle_index: number) {
    this.vehicles.at(vehicle_index).get('extended_use').disable();
    this.vehicles.at(vehicle_index).get('non_equine_trade').disable();
    // this.vehicles.at(vehicle_index).get('drivers').disable();
    this.vehicles.at(vehicle_index).get('equine_trade_drivers').disable();
    this.vehicles.at(vehicle_index).get('non_equine_trade_drivers').disable();
    if (event.value == false) {
      // this.displayExtendedUseSection = false;
    } else {
      this.vehicles.at(vehicle_index).get('extended_use').enable();
      // this.displayExtendedUseSection = true;
    }
  }

  is_proposer_non_equine: boolean = false;
  onproposerNonEquineChange(event, vehicle_index: number) {
    if (event.checked) {
      this.is_proposer_non_equine = true;
      this.vehicles.at(vehicle_index).get('non_equine_trade').enable();
    } else {
      this.is_proposer_non_equine = false;
      this.vehicles.at(vehicle_index).get('non_equine_trade').disable();
    }
  }

  is_driver_equine: boolean = false;
  onDriverEquineChange(event, vehicle_index: number) {
    if (event.checked) {
      this.is_driver_equine = true;
      this.vehicles.at(vehicle_index).get('equine_trade_drivers').enable();
    } else {
      this.is_driver_equine = false;
      this.vehicles.at(vehicle_index).get('equine_trade_drivers').disable();
    }
  }

  is_driver_non_equine: boolean = false;
  onDriverNonEquineChange(event, vehicle_index: number) {
    if (event.checked) {
      this.is_driver_non_equine = true;
      this.vehicles.at(vehicle_index).get('non_equine_trade_drivers').enable();
    } else {
      this.is_driver_non_equine = false;
      this.vehicles.at(vehicle_index).get('non_equine_trade_drivers').disable();
    }
  }

  /*
  * Drivers
  */
  initComplexListDrivers() {
    return this.fb.group({
      id: ['', Validators.required],
      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      dob: ['', Validators.required],
      licence_type: ['', Validators.required],
      licence_length: ['', Validators.required],
    })
  }
  initDrivers() {
    return this.fb.group({
      'id': ['', [Validators.required]]
    })
  }

  driverControls(vehicle_index: number): FormArray {
    return this.vehicles.at(vehicle_index).get("drivers") as FormArray
  }

  // Type 1: Drivers, 2: Equine Drivers, 3: Non-Equine Drivers
  addExistingDriver(vehicle_index: number, type: number = 1) {
    switch (type) {
      case 1:
        this.driverControls(vehicle_index).push(this.initDrivers());
        break;
      case 2:
        this.equineDriverControls(vehicle_index).push(this.initDrivers());
        break;
      case 3:
        this.nonEquineDriverControls(vehicle_index).push(this.initDrivers());
        break;
    }

  }

  current_vehicle_index;
  driver_type: number = 1;
  addNewDriver(vehicle_index: number, drivers, type: number = 1) {
    this.driver_title = '';
    this.driver_forename = '';
    this.driver_surname = '';
    this.dont_have_driver_name = false;
    this.modalService.open(drivers, { centered: true });
    this.current_vehicle_index = vehicle_index;
    this.driver_type = type;
  }

  removeDrivers(vehicle_index: number, driver_index: number, type: number = 1) {
    switch (type) {
      case 1:
        this.driverControls(vehicle_index).removeAt(driver_index);
        break;
      case 2:
        this.equineDriverControls(vehicle_index).removeAt(driver_index);
        break;
      case 3:
        this.nonEquineDriverControls(vehicle_index).removeAt(driver_index);
        break;
    }
  }


  /*
  * Equine Drivers
  */
  equineDriverControls(vehicle_index: number): FormArray {
    return this.vehicles.at(vehicle_index).get("equine_trade_drivers") as FormArray
  }

  /*
 * Non- Equine Drivers
 */
  nonEquineDriverControls(vehicle_index: number): FormArray {
    return this.vehicles.at(vehicle_index).get("non_equine_trade_drivers") as FormArray
  }

  /*
  * Event if do not have driver name
  */
  dont_have_driver_name: boolean = false;
  onNoDriverNameChange(event) {
    if (event.checked) {
      this.driver_title = '';
      this.driver_forename = '';
      this.driver_surname = '';
      this.dont_have_driver_name = true;
    } else {
      this.dont_have_driver_name = false;
    }
  }

  driver_title;
  driver_forename;
  driver_surname;
  drivers_list = [];
  UniqueID: string = '';
  validationMsgForPopUp: string = '';
  createNewDriver() {
    if (!this.dont_have_driver_name) {
      if (!this.driver_title || !this.driver_forename) {
        this.validationMsgForPopUp = "Please select the title and enter driver's forename.";
        return;
      }
    } else {
      this.driver_forename = 'No name provided';
    }
    // GET UNIQUE ID FOR DRIVERS
    this.as.getUniqueID(this.authDetails)
      .subscribe(
        (response: any) => {
          if (response.meta) {
            this.UniqueID = response.meta.id;
            let data = {
              'id': response.meta.id,
              'title': this.driver_title,
              'first_name': this.driver_forename,
              'last_name': this.driver_surname,
            };
            this.drivers_list.push(data);

            let dataCtrls = this.fb.group(data)

            switch (this.driver_type) {
              case 1:
                this.driverControls(this.current_vehicle_index).push(dataCtrls);
                break;
              case 2:
                this.equineDriverControls(this.current_vehicle_index).push(dataCtrls);
                break;
              case 3:
                this.nonEquineDriverControls(this.current_vehicle_index).push(dataCtrls);
                break;
            }

            this.driversFullList.push(dataCtrls)

            this.modalService.dismissAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );


  }

  /*
  * Registered Owner
  */
  onRegisteredOwnerChange(event, vehicle_index: number) {
    this.enableOwnerField(event.value, vehicle_index);
  }

  enableOwnerField(value, vehicle_index) {
    this.enableOwner(value, vehicle_index);
  }

  enableOwner(value, vehicle_index) {
    if (!value) {
      this.vehicles.at(vehicle_index).get('owner').enable();
    } else {
      this.vehicles.at(vehicle_index).get('owner').disable();
    }
  }

  /*
  * Postcode section If Vehicle kept Overnight
  */
  onkeptOvernightChange(event, vehicle_index: number) {
    this.enablePostcodeIfKeptOvernight(event.value, vehicle_index);
  }

  enablePostcodeIfKeptOvernight(value, vehicle_index) {
    this.enablePostcode(value, vehicle_index);
  }

  enablePostcode(value, vehicle_index) {
    if (value == null) {
      this.vehicles.at(vehicle_index).get('postcode').disable();
    } else {
      if (!value) {
        this.vehicles.at(vehicle_index).get('postcode').enable();
      } else {
        this.vehicles.at(vehicle_index).get('postcode').disable();
      }
    }

  }

  /*
  * Add Another Horsebox
  */
  addHorsebox() {
    this.vehicles.push(this.initVehicles());
  }

  removeHorseboxes(index: number) {
    this.vehicles.removeAt(index);
  }

  /*
  * Event on Driving Restriction Change
  */
  activateDrivers: boolean = false;
  onDrivingRestrictionChange(event, vehicle_index) {
    this.vehicles.at(vehicle_index).get('drivers').disable();
    this.activateDriversSection(event.value, vehicle_index);
  }

  activateDriversSection(value, vehicle_index) {
    value = value.toLowerCase();
    switch (value) {
      case 'insured and spouse':
        this.activateDrivers = true;
        break;
      case 'insured and named':
        this.activateDrivers = true;
        break;
      case 'named drivers':
        this.activateDrivers = true;
        break;
      case 'named drivers excluding policyholder':
        this.activateDrivers = true;
        break;
      case 'any driver aged over 21 other than those named':
        this.activateDrivers = true;
        break;
      case 'any driver aged over 25 other than those named':
        this.activateDrivers = true;
        break;
      case 'any driver aged over 30 other than those named':
        this.activateDrivers = true;
        break;
      default:
        this.activateDrivers = false;
        break;
    }

    if (this.modelObj.drivers.length > 0) {
      this.driverControls(vehicle_index).clear();
      this.modelObj.drivers.forEach((res1) => {
        if (res1.id) {
          let data = this.fb.group({ 'id': res1.id })
          this.driverControls(vehicle_index).push(data);
        }
      })
    }
  }


  /*
  * Individual 
  * Add Existing Drivers
  */
  addIndividualExistingDriver() {

  }

  onSubmit() {
    this.submitted = true;
    if (!this.frmFields.valid) {
      return false;
    }
    let formData = this.frmFields.value;
    this.modelObj.vehicles = formData.vehicles;
    this.modelObj.drivers.forEach((res) => {
      formData.drivers.forEach((res1) => {
        res1.claims = res.claims;
        res1.convictions = res.convictions;
      })
    })
    if (formData.drivers && formData.drivers.length == 1) {
      formData.drivers.forEach((res) => {
        if (res.first_name) {
          var drivers = this.modelObj.drivers.filter(x => x.id == res.id);
          if (drivers.length == 0) {
            this.modelObj.drivers.push(res);
          }
        }
      })

    }
    //this.modelObj.drivers = formData.drivers;
    let drivers = [];
    let non_equine_trade_drivers = [];
    let equine_trade_drivers = [];
    formData.vehicles.forEach((res) => {
      if (res.drivers) {
        drivers = [];
        res.drivers.forEach((res1) => {
          drivers.push(res1.id);
        })
      }
      if (res.non_equine_trade_drivers) {
        res.non_equine_trade_drivers.forEach((res1) => {
          non_equine_trade_drivers.push(res1.id);
        })
      }
      if (res.equine_trade_drivers) {
        res.equine_trade_drivers.forEach((res1) => {
          equine_trade_drivers.push(res1.id);
        })
      }
    })

    this.modelObj.vehicles.forEach((res) => {
      res.drivers = drivers;
      res.non_equine_trade_drivers = non_equine_trade_drivers;
      res.equine_trade_drivers = equine_trade_drivers;
      res.breakdown_cover = false;  // changed 06-04-2023 - need to remove btreakdown
    })

    // let quote_id = (this.route.snapshot.params.id) ? this.route.snapshot.params.id : this.modelObj.id;

    if (this.modelObj.type == 2) {
      this.modelObj.policy_id = null;
      this.modelObj.effective_date = null;
    }

    // if(this.modelObj.drivers && this.modelObj.drivers.length == 1) {
    //   if(!this.modelObj.drivers[0].id) {
    //     this.modelObj.drivers = [];
    //   }
    // }

    let data = {
      data: {
        "type": this.modelObj.type,
        "attributes": this.modelObj,
        // "id": (this.route.snapshot.params.id) ? this.route.snapshot.params.id : this.modelObj.id
        "id": this.modelObj.id,
        "broker_id": this.modelObj.broker_id,
        "sequence_number": this.modelObj.sequence_number,
      }
    };

    this.as.saveQuote(data)
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (res.result.quote_data && res.result.quote_data.quote_versions[0].amlin_data) {
              this.modelObj = res.result.quote_data.quote_versions[0].amlin_data.attributes.application;
              this.modelObj.id = res.result.quote_data.quote_versions[0].amlin_data.id;
              this.modelObj.broker_id = res.result.quote_data.quote_versions[0].broker_id;
              this.modelObj.reference = res.result.quote_data.quote_versions[0].amlin_data.attributes.reference;
              this.modelObj.status = res.result.quote_data.quote_versions[0].amlin_data.attributes.status;
              this.modelObj.company_id = res.result.quote_data.quote_versions[0].company_id;
              this.modelObj.vehicles.forEach((res) => {
                res.breakdown_cover = false;
                let drivers = [];
                if (res.drivers && res.drivers.length > 0) {
                  res.drivers.forEach((d) => {
                    drivers.push(d.driver);
                  })
                }

                res.drivers = drivers;
              })
              this.modelObjUpdated.emit(this.modelObj);
            };
          } else {
            this._snackBar.open(res.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}
