import { Component, OnInit } from '@angular/core';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../quote.service';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteList } from '../../model/quote/quote-list.model';
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../services/user-info.service';
import { PolicyService } from '../../policy/policy.service';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.css', '../quote.component.css']
})
export class QuoteListComponent implements OnInit {
  quote = new QuoteList();
  EQUINE_ID = environment.EQUINE_ID;
  ALPACA_ID = environment.ALPACA_ID;

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Inception Date', field: 'policy_start_date', sortable: true, filter: false, width: 230,
      cellRenderer: function (params) {
        return params.data.policy_start_date;
        // return formatDate(params.data.policy_start_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: 'Quote No.', field: 'quote_ref_number', sortable: true, filter: false, width: 250,
      cellRenderer: function (params) {
        if (params.data.product_id == environment.AMLINE_ID) {
          if (params.data.status != 'referred' && params.data.status != 'issued' && params.data.status != 'declined') {
            return '<a data-action-type="edit" id="' + params.data.amlin_id + '">' + params.data.amlin_reference + '</a>';
          } else {
            return '<a data-action-type="referred" class="text-danger ml-2">' + params.data.amlin_reference + '</a>';
          }
        } else {
          return '<a data-action-type="edit" id="' + params.data.quote_meta_id + '">' + params.data.quote_ref_number + '</a>';
        }
      }
    },
    { headerName: 'Ver. No.', field: 'current_version', sortable: true, filter: false, width: 100 },
    {
      headerName: 'Customer Name', field: 'first_name', sortable: true, filter: false, width: 260,
      cellRenderer: function (params) {
        // return params.data.first_name + ' ' + params.data.last_name;
        return params.data.customer_name;
      },
      tooltip: function (params) {
        // return params.data.first_name + ' ' + params.data.last_name;
        return params.data.customer_name;
      },
    },
    {
      headerName: 'Broker Company', field: 'broker_name', sortable: true, width: 300,
      hide: (this.sessionSt.retrieve('company_role') == 'Broker') ? true : false,
      tooltip: function (params) {
        return params.data.broker_name;
      },
    },
    {
      headerName: 'Quote Type', field: 'quote_type', sortable: true, filter: false,
      tooltip: function (params) {
        return params.data.quote_type;
      },
    },
    {
      headerName: 'Status', field: 'original_quote_status', sortable: true, width: 250,
      cellRenderer: function (params) {
        if (params.data.amlin_id) {
          return params.data.amlin_status;
        } else {
          return params.data.original_quote_status;
        }
      },
      tooltip: function (params) {
        if (params.data.amlin_id) {
          return params.data.amlin_status;
        } else {
          return params.data.original_quote_status;
        }
      },
    },
    {
      headerName: 'Product Name', field: 'product_name', sortable: true,
      tooltip: function (params) {
        return params.data.product_name;
      },
    },
    {
      headerName: 'Gross Premium', field: 'policy_premium', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.policy_premium != null) {
          amount = params.data.policy_premium;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.policy_premium;
      },
    },
    {
      headerName: 'IPT', field: 'ipt_fee', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.ipt_fee != null) {
          amount = params.data.ipt_fee;
        }
        return amount + '%';
      },
      tooltip: function (params) {
        return params.data.ipt_fee + '%';
      },
    },
    {
      headerName: 'Fees', field: 'fees', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.fees != null) {
          amount = params.data.fees;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.fees;
      },
    },
    {
      headerName: 'Total Premium', field: 'total_policy_cost', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.total_policy_cost != null) {
          amount = params.data.total_policy_cost;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.total_policy_cost;
      },
    },
    {
      headerName: 'Action', width: 250,
      cellRenderer: function (params) {
        let links = '';
        if (!params.data.isInsurer && !params.data.isBroker) {
          links += '<a  title="Edit Quote Details" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.quote_meta_id + '"></i></a>';
        }
        if (params.data.product_id == environment.ALPACA_ID) {
          links += '<a class="text-primary ml-2" target="_blank" href="alpaca-admin/quote-details/' + params.data.quote_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }
        else if (params.data.product_id == environment.LIVESTOCK_ID) {
          links += '<a class="text-primary ml-2" target="_blank" href="livestock/quote-details/' + params.data.quote_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }
        else if (params.data.product_id == environment.ALLIANZ_EQUINE_ID) {
          links += '<a class="text-primary ml-2" target="_blank" href="allianz-equine/quote-details/' + params.data.quote_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }
        else if (params.data.product_id == environment.AJG_ID) {
          links += '<a class="text-primary ml-2" target="_blank" href="ajg/quote-details/' + params.data.quote_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }
        else if (params.data.product_id == environment.AMLINE_ID) {
          links += '<a class="text-primary ml-2" target="_blank" href="amline/quote-details/' + params.data.amlin_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }
        else {
          links += '<a class="text-primary ml-2" target="_blank" href="quote-details/' + params.data.quote_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }

        if (params.data.isAdmin) {
          links += '<a title="Delete Quote" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
        }
        return links;
      }
    }
  ];
  rowData: any = [];

  constructor(private quoteService: QuoteService, private sessionSt: LocalStorageService, private userService: UserInfoService,
    private router: Router, private modalService: NgbModal, private titlecasePipe: TitleCasePipe, private _snackBar: MatSnackBar, private policyService: PolicyService) { }

  brokers;
  quoteTypes;
  products = [];
  policyStatuses = [];
  ngOnInit(): void {
    this.userService.getUserDetails();
    // this.quote.company_id = this.sessionSt.retrieve('userInfo').company_id;
    this.searchRecords();
    this.sessionSt.store('quote_ref_id', null);
    // Get All Brokers
    this.quoteService.getBrokers()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get Quote Types
    this.quoteService.getQuoteTypes(0)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteTypes = response.result.types;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyStatuses = response.result.quote_insurance_status;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get All Products
    this.quoteService.getProducts()
      .subscribe(
        (response: any) => {
          if (response.success) {
            // this.products = response.result;
            let companyProducts = this.sessionSt.retrieve('companyProducts');
            response.result.forEach((res) => {
              let cp = companyProducts.filter(x => x.product_id == res.id);
              if (cp.length > 0) {
                this.products.push(res);
              }

            })
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * SEARCHING
  */
  searchRecords() {
    let temp = 0; //--- Remove it later
    // if (!this.quote.company_id) {
    //  this.quote.company_id = this.sessionSt.retrieve('userInfo').company_id;
    // }

    // if (this.quote.from != '' && this.quote.from != null) {
    //   let formattedDate = formatDate(this.quote.from, 'yyyy-MM-dd', 'en-US');
    //   this.quote.from = formattedDate;
    // }

    // if (this.quote.to != '' && this.quote.to != null) {
    //   let formattedDateTo = formatDate(this.quote.to, 'yyyy-MM-dd', 'en-US');
    //   this.quote.to = formattedDateTo;
    // }
    this.quote.broker_id = this.sessionSt.retrieve('userInfo').id;
    this.quoteService.searchQuote(this.quote)
      .subscribe(
        (response: any) => {
          if (response.success) {
            response.result.forEach((res) => {
              res.first_name = this.titlecasePipe.transform(res.first_name);
              res.last_name = this.titlecasePipe.transform(res.last_name);
              if (this.sessionSt.retrieve('is_admin')) {
                res.isAdmin = true;
              }
              if (this.sessionSt.retrieve('is_insurer')) {
                res.isInsurer = true;
              }
              if (this.sessionSt.retrieve('is_broker')) {
                res.isBroker = true;
              }


            })


            this.rowData = response.result;
            this.quote.total_records = response.total_records;
            this.quote.total_net_rate = response.total_net_rate;
            this.quote.total_gross_premium = response.total_gross_premium;
            this.quote.total_fees = response.total_fees;
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  /*
  * GRID BUTTONS ACTION
  */

  msg: string = '';
  quote_id: string = '';
  rowIndex: number = 0;

  onGridRowClicked(e: any, content) {
    if (e) {
      let quote_meta_id = e.event.target.getAttribute("id");
      this.quote_id = e.data.quote_id;
      this.rowIndex = e.rowIndex;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            if (e.data.product_id == environment.ALPACA_ID) {
              this.router.navigate(['/pre-qualifier', 2, e.data.quote_id]);
            } else if (e.data.product_id == environment.LIVESTOCK_ID) {
              this.router.navigate(['/pre-qualifier', 3, e.data.quote_id]);
            }
            else if (e.data.product_id == environment.ALLIANZ_EQUINE_ID) {
              this.router.navigate(['/pre-qualifier', 4, e.data.quote_id]);
            }
            else if (e.data.product_id == environment.AJG_ID) {
              this.router.navigate(['/pre-qualifier', 5, e.data.quote_id]);
            }
            else if (e.data.product_id == environment.AMLINE_ID) {
              this.router.navigate(['/pre-qualifier', 6, e.data.amlin_id]);
            }
            else {
              this.router.navigate(['/pre-qualifier', 1, e.data.quote_id]);
            }
            break;
          }
        case "referred":
          {
            this.msg = 'You are not able to edit a referred quote. ';
            this.modalService.open(content, { centered: true });
            break;
          }
        case "delete":
          {
            this.msg = 'Are you sure you want to delete this quote?';
            this.modalService.open(content, { centered: true });
            break;
          }
      }
    }
  }

  /*
  * DELETE QUOTE
  */
  deleteQuote(quote_id, rowIndex) {
    let data: any = '';
    data = {
      quote_id: quote_id
    };

    this.quoteService.deleteQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowData.splice(rowIndex, 1);
            this.gridOptions.api.setRowData(this.rowData)
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * CLEAR FILTERS
  */
  clearFilters(f) {
    f.reset({});
    this.quote.from = '';
    this.quote.to = '';
    this.searchRecords();
  }


  /*
* HELP TEXT POPUP
*/
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }
}
