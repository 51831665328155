import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {DataTablesModule} from 'angular-datatables';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { FirstCharUpperDirective } from './directives/firstCharUpper.directive';


import { ModalModule } from './_modal';

import { SharedService } from './services/shared.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptorService } from './services/interceptors/loader-interceptor.service';

import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SidebarComponent } from './_layouts/sidebar/sidebar.component';
import { CompanyComponent } from './company/company.component';
import { LayoutLoginComponent } from './_layouts/layout-login/layout-login.component';
import { LayoutInnerpagesComponent } from './_layouts/layout-innerpages/layout-innerpages.component';
import { AppRoutingModule } from './app-routing.module';
import { CompanyUpsertComponent } from './company/company-upsert.component';
import { LoaderComponent } from './_layouts/loader/loader.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { CustomMaterialModule } from './confirm-dialog/confirm-dialog.module';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';

import {LoginGuard} from './auth/auth.guard';
import { QuoteComponent } from './quote/quote.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullDetailsComponent } from './quote/full-details/full-details.component';
import {MatDatepickerModule } from '@angular/material/datepicker'; 
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { IndicativeQuoteComponent } from './quote/indicative-quote/indicative-quote.component';
const moment = _rollupMoment || _moment;
import {MatExpansionModule} from '@angular/material/expansion';
import { FactStatementComponent } from './quote/fact-statement/fact-statement.component'; 
import { AgGridModule } from 'ag-grid-angular';
import { PaymentComponent } from './quote/payment/payment.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { SubmissionComponent } from './quote/submission/submission.component';
import { QuoteListComponent } from './quote/quote-list/quote-list.component';
import { CalculationsComponent } from './quote/calculations/calculations.component';
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { TitleCasePipe } from '@angular/common';
import { TitleCaseDirective } from './directives/title-case.directive';
import { DialogComponent } from './_layouts/dialog/dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuoteDetailsComponent } from './quote/quote-details/quote-details/quote-details.component';
import { OverviewComponent } from './quote/quote-details/overview/overview.component';
import { QuoteFullDetailsComponent } from './quote/quote-details/quote-full-details/quote-full-details.component';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { QuoteSofComponent } from './quote/quote-details/quote-sof/quote-sof.component';
import { FinancialsComponent } from './quote/quote-details/financials/financials.component';
import { DocumentsComponent } from './quote/quote-details/documents/documents.component';
import { NotesComponent } from './quote/quote-details/notes/notes.component';
import { PolicyComponent } from './policy/policy.component';
import { PolicyOverviewComponent } from './policy/policy-overview/policy-overview.component';
import { PolicyFullDetailsComponent } from './policy/policy-full-details/policy-full-details.component';
import { PolicySofComponent } from './policy/policy-sof/policy-sof.component';
import { PolicyFinancialsComponent } from './policy/policy-financials/policy-financials.component';
import { PolicyDocumentsComponent } from './policy/policy-documents/policy-documents.component';
import { PolicyClaimsComponent } from './policy/policy-claims/policy-claims.component';
import { PolicyNotesComponent } from './policy/policy-notes/policy-notes.component';
import { PolicyListComponent } from './policy/policy-list/policy-list.component';
import { BordereauComponent } from './bordereau/bordereau.component';
import { MiComponent } from './dashboard/mi/mi.component';
import { NumbersDirective } from './directives/numbers.directive';
import { PolicyComplianceComponent } from './policy/policy-compliance/policy-compliance.component';

import { AdminAlQuoteComponent } from './quote/alpaca/al-quote/al-quote.component';
import { AdminAlIndicativeQuoteComponent } from './quote/alpaca/al-quote/al-indicative-quote/al-indicative-quote.component';
import { AdminAlIndicativeQuoteDetailsComponent } from './quote/alpaca/al-quote/al-indicative-quote-details/al-indicative-quote-details.component';
import { AdminAlFullDetailsComponent } from './quote/alpaca/al-quote/al-full-details/al-full-details.component';
import { AdminAlSofComponent } from './quote/alpaca/al-quote/al-sof/al-sof.component';
import { AdminAlQuotationComponent } from './quote/alpaca/al-quote/al-quotation/al-quotation.component';
import { AdminAlPolicyAndPaymentComponent } from './quote/alpaca/al-quote/al-policy-and-payment/al-policy-and-payment.component';
import { AdminAlCalculationsComponent } from './quote/alpaca/al-quote/al-calculations/al-calculations.component';

import { AlQuoteDetailsComponent } from './quote/alpaca/quote-details/quote-details/quote-details.component';
import { AlOverviewComponent } from './quote/alpaca/quote-details/overview/overview.component';
import { AlQuoteFullDetailsComponent } from './quote/alpaca/quote-details/quote-full-details/quote-full-details.component';
import { AlQuoteSofComponent } from './quote/alpaca/quote-details/quote-sof/quote-sof.component';
import { AlFinancialsComponent } from './quote/alpaca/quote-details/financials/financials.component';
import { AlDocumentsComponent } from './quote/alpaca/quote-details/documents/documents.component';
import { AlNotesComponent } from './quote/alpaca/quote-details/notes/notes.component';

import { AlPolicyDetailsComponent } from './policy/alpaca/policy-details/policy-details.component';
import { AlPolicyOverviewComponent } from './policy/alpaca/overview/overview.component';
import { AlPolicyFullDetailsComponent } from './policy/alpaca/policy-full-details/policy-full-details.component';
import { AlPolicySofComponent } from './policy/alpaca/policy-sof/policy-sof.component';
import { AlPolicyFinancialsComponent } from './policy/alpaca/financials/financials.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { QuoteEmailComponent } from './quote-email/quote-email.component';
import { QuoteEmailViewComponent } from './quote-email/quote-email-view/quote-email-view.component';
import { EditorModule } from "@tinymce/tinymce-angular";
import { QuoteEmailComposeComponent } from './quote-email/quote-email-compose/quote-email-compose.component';
import { PolicyPaymentComponent } from './policy/policy-payment/policy-payment.component';

import { ProductBasedConfigurationComponent } from './product-based-configuration/product-based-configuration.component';

import { QuoteNotesComponent } from './quote-notes/quote-notes.component';
import { QuoteEndorsementComponent } from './quote-endorsement/quote-endorsement.component';
import { BordereauLiveComponent } from './bordereau/bordereau-live/bordereau-live.component';
import { BordereauLapseComponent } from './bordereau/bordereau-lapse/bordereau-lapse.component';
import { QuoteDocumentsComponent } from './quote-documents/quote-documents.component';
import { QuoteVersionsComponent } from './quote-versions/quote-versions.component';

import { QuotePreQualifierComponent } from './quote-pre-qualifier/quote-pre-qualifier.component';
import { LivestockComponent } from './quote/livestock/livestock.component';
import { CoverOptionsComponent } from './quote/livestock/cover-options/cover-options.component';
import { LivestockCommonSectionsComponent } from './quote/livestock/livestock-common-sections/livestock-common-sections.component';
import { QuotationComponent } from './quote/livestock/quotation/quotation.component';
import { ProposerComponent } from './quote/livestock/proposer/proposer.component';
import { AnimalsInsuranceComponent } from './quote/livestock/animals-insurance/animals-insurance.component';
import { SummaryComponent } from './quote/livestock/summary/summary.component';
import { LivestockCalculationsComponent } from './quote/livestock/livestock-calculations/livestock-calculations.component';
import { LivestockSofComponent } from './quote/livestock/livestock-sof/livestock-sof.component';
import { LvPaymentConfirmationComponent } from './quote/livestock/lv-payment-confirmation/lv-payment-confirmation.component';
import { LvPolicyPaymentComponent } from './quote/livestock/lv-policy-payment/lv-policy-payment.component';
import { LvFullDetailsComponent } from './quote/livestock/lv-full-details/lv-full-details.component';
import { SaleFullDetailsComponent } from './quote/livestock/sale-full-details/sale-full-details.component';
import { SaleQuoteDetailsComponent } from './quote/livestock/sale-quote-details/sale-quote-details.component';
import { SaleAndClaimsComponent } from './quote/livestock/sale-and-claims/sale-and-claims.component';
import { ProposerOtherDetailsComponent } from './quote/livestock/proposer-other-details/proposer-other-details.component';
import { QuotationConfirmationComponent } from './quote/livestock/quotation-confirmation/quotation-confirmation.component';
import { CommissionsComponent } from './quote/livestock/commissions/commissions.component';
import { LvQuoteDetailsComponent } from './quote/livestock/quote-details/quote-details/quote-details.component';
import { LivestockViewComponent } from './quote-view/livestock-view/livestock-view.component';
import { QvOverviewComponent } from './quote-view/qv-overview/qv-overview.component';
import { QvFullDetailsComponent } from './quote-view/livestock-view/qv-full-details/qv-full-details.component';


import { QuoteAgqComponent } from './quote/quote-details/quote-agq/quote-agq.component';
import { AlQuoteAgqComponent } from './quote/alpaca/quote-details/quote-agq/quote-agq.component';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';

import { AllianzComponent } from './quote/allianz-equine/allianz.component';
import { AllianzFullDetailsComponent } from './quote/allianz-equine/allianz-full-details/allianz-full-details.component';
import { AllianzIndicativeQuoteComponent } from './quote/allianz-equine/allianz-indicative-quote/allianz-indicative-quote.component';
import { AllianzFactStatementComponent } from './quote/allianz-equine/fact-statement/fact-statement.component';
import { AllianzPaymentComponent } from './quote/allianz-equine/payment/payment.component';
import { AllianzSubmissionComponent } from './quote/allianz-equine/submission/submission.component';
import { AllianzQuoteDetailsComponent } from './quote/allianz-equine/quote-details/quote-details/quote-details.component';
import { AllianzOverviewComponent } from './quote/allianz-equine/quote-details/overview/overview.component';
import { AllianzQuoteAgqComponent } from './quote/allianz-equine/quote-details/quote-agq/quote-agq.component';
import { AllianzQuoteFullDetailsComponent } from './quote/allianz-equine/quote-details/quote-full-details/quote-full-details.component';
import { AllianzQuoteSofComponent } from './quote/allianz-equine/quote-details/quote-sof/quote-sof.component';
import { AllianzCalculationsComponent } from './quote/allianz-equine/calculations/calculations.component';

import { AllianzPolicyDetailsComponent } from './policy/allianz-equine/policy-details/policy-details.component';

import { AjgIndicativeQuoteComponent } from './quote/ajg/indicative-quote/ajg-indicative-quote.component';
import { AjgCalculationsComponent } from './quote/ajg/calculations/ajg-calculations.component';
import { AjgQuoteComponent } from './quote/ajg/ajg-quote.component';
import { AjgFullDetailsComponent } from './quote/ajg/full-details/ajg-full-details.component';
import { AjgFactStatementComponent } from './quote/ajg/fact-statement/ajg-fact-statement.component';
import { AjgPaymentComponent } from './quote/ajg/payment/ajg-payment.component';
import { AjgSubmissionComponent } from './quote/ajg/submission/ajg-submission.component';
import { AjgQuoteViewComponent } from './quote/ajg/quote-view/ajg-quote-view.component';
import { AjgQvFullDetailsComponent } from './quote-view/ajg-qv-full-details/ajg-qv-full-details.component';
import { QuoteViewSofComponent } from './quote-view/quote-view-sof/quote-view-sof.component';
import { PolicyViewComponent } from './policy/policy-view/policy-view.component';
import { UnderwritingComponent } from './policy/underwriting/underwriting.component';
import { AmlineComponent } from './quote/amline/amline.component';
import { ProposerDetailsComponent } from './quote/amline/proposer-details/proposer-details.component';
import { VehiclesComponent } from './quote/amline/vehicles/vehicles.component';
import { DriversComponent } from './quote/amline/drivers/drivers.component';
import { AmQuotationComponent } from './quote/amline/am-quotation/am-quotation.component';
import { AmQuoteViewComponent } from './quote/amline/am-quote-view/am-quote-view.component';
import { AmOverviewComponent } from './quote/amline/am-quote-view/am-overview/am-overview.component';
import { AmFullDetailsComponent } from './quote/amline/am-quote-view/am-full-details/am-full-details.component';
import { FleetExperienceComponent } from './quote/amline/fleet-experience/fleet-experience.component';
import { AmDocumentsComponent } from './quote/amline/am-documents/am-documents.component';
import { AmDriversViewComponent } from './quote/amline/am-quote-view/am-drivers-view/am-drivers-view.component';
import { AmClaimsComponent } from './quote/amline/am-claims/am-claims.component';
import { AmConvictionsComponent } from './quote/amline/am-convictions/am-convictions.component';
import { AmPolicyComponent } from './policy/amlin/am-policy/am-policy.component';
import { AmFleetExperiencesViewComponent } from './quote/amline/am-quote-view/am-fleet-experiences-view/am-fleet-experiences-view.component';
import { AmFinancialsComponent } from './quote/amline/am-quote-view/am-financials/am-financials.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    ResetPasswordComponent,
    SidebarComponent,
    CompanyComponent,
    LayoutLoginComponent,
    LayoutInnerpagesComponent,
    CompanyUpsertComponent,
    LoaderComponent,
    UserComponent,
    UserListComponent,
    ConfirmDialogComponent,
    QuoteComponent,
    FullDetailsComponent,
    IndicativeQuoteComponent,
    FactStatementComponent,
    PaymentComponent,
    SubmissionComponent,
    QuoteListComponent,
    CalculationsComponent,
    DecimalNumbersDirective,
    TitleCaseDirective,
    FirstCharUpperDirective,
    DialogComponent,
    QuoteDetailsComponent,
    OverviewComponent,
    QuoteFullDetailsComponent,
    UpperCaseDirective,
    QuoteSofComponent,
    FinancialsComponent,
    DocumentsComponent,
    NotesComponent,
    PolicyComponent,
    PolicyOverviewComponent,
    PolicyFullDetailsComponent,
    PolicySofComponent,
    PolicyFinancialsComponent,
    PolicyDocumentsComponent,
    PolicyClaimsComponent,
    PolicyNotesComponent,
    PolicyListComponent,
    BordereauComponent,
    MiComponent,
    NumbersDirective,
    PolicyComplianceComponent,

    AdminAlQuoteComponent,
    AdminAlIndicativeQuoteComponent,
    AdminAlIndicativeQuoteDetailsComponent,
    AdminAlFullDetailsComponent,
    AdminAlSofComponent,
    AdminAlQuotationComponent,
    AdminAlPolicyAndPaymentComponent,
    AdminAlCalculationsComponent,

    AlQuoteDetailsComponent,
    AlOverviewComponent,
    AlQuoteFullDetailsComponent,
    AlQuoteSofComponent,
    AlFinancialsComponent,
    AlDocumentsComponent,
    AlNotesComponent,

    AlPolicyDetailsComponent,
    AlPolicyOverviewComponent,
    AlPolicyFullDetailsComponent,
    AlPolicySofComponent,
    AlPolicyFinancialsComponent,
    PageNotFoundComponent,
    QuoteEmailComponent,
    QuoteEmailViewComponent,
    QuoteEmailComposeComponent,
    PolicyPaymentComponent,
    ProductBasedConfigurationComponent,

    QuoteNotesComponent,
    QuoteEndorsementComponent,
    BordereauLiveComponent,
    BordereauLapseComponent,
    QuoteDocumentsComponent,
    QuoteVersionsComponent,

    QuotePreQualifierComponent,
    LivestockComponent,
    CoverOptionsComponent,
    LivestockCommonSectionsComponent,
    QuotationComponent,
    ProposerComponent,
    AnimalsInsuranceComponent,
    SummaryComponent,
    LivestockCalculationsComponent,
    LivestockSofComponent,
    LvPaymentConfirmationComponent,
    LvPolicyPaymentComponent,
    LvFullDetailsComponent,
    SaleFullDetailsComponent,
    SaleQuoteDetailsComponent,
    SaleAndClaimsComponent,
    ProposerOtherDetailsComponent,
    QuotationConfirmationComponent,
    CommissionsComponent,
    LvQuoteDetailsComponent,
    LivestockViewComponent,
    QvOverviewComponent,
    QvFullDetailsComponent,

    QuoteAgqComponent,
    AlQuoteAgqComponent,
    UnauthorizedAccessComponent,
    AllianzComponent,
    AllianzFullDetailsComponent,
    AllianzIndicativeQuoteComponent,
    AllianzFactStatementComponent,
    AllianzPaymentComponent,
    AllianzSubmissionComponent,
    AllianzQuoteDetailsComponent,
    AllianzOverviewComponent,
    AllianzQuoteAgqComponent,
    AllianzQuoteFullDetailsComponent,
    AllianzQuoteSofComponent,
    AllianzCalculationsComponent,

    AllianzPolicyDetailsComponent,
    AjgIndicativeQuoteComponent,
    AjgCalculationsComponent,
    AjgQuoteComponent,
    AjgFullDetailsComponent,
    AjgFactStatementComponent,
    AjgPaymentComponent,
    AjgSubmissionComponent,
    AjgQuoteViewComponent,
    AjgQvFullDetailsComponent,
    QuoteViewSofComponent,
    PolicyViewComponent,
    UnderwritingComponent,
    AmlineComponent,
    ProposerDetailsComponent,
    VehiclesComponent,
    DriversComponent,
    AmQuotationComponent,
    AmQuoteViewComponent,
    AmOverviewComponent,
    AmFullDetailsComponent,
    FleetExperienceComponent,
    AmDocumentsComponent,
    AmDriversViewComponent,
    AmClaimsComponent,
    AmConvictionsComponent,
    AmPolicyComponent,
    AmFleetExperiencesViewComponent,
    AmFinancialsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    ReactiveFormsModule,
    ModalModule,    
    AppRoutingModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatExpansionModule,
    AgGridModule.withComponents([]),
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgbModule,
    EditorModule,
    AngularEditorModule 
  ],
  providers: [SharedService,ConfirmDialogService,LoaderService,LoginGuard,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},TitleCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
