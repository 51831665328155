<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit()">
    <div class="form_sections">
        <h4><u>Period of cover from</u></h4>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group w-100">
                    <input type="text" required name="inception_date" formControlName="inception_date" placeholder="Cover From"
                        class="form-control form-control-sm datepicker" matInput (focus)="coverFromDatepicker.open()"
                        [matDatepicker]="coverFromDatepicker" (dateChange)="onPolicyStartDateChange($event)" [disabled]="quote_type == 3"
                        [ngClass]="{ 'is-invalid': (f.inception_date.touched || submitted) && f.inception_date.errors?.required }">
                    <mat-datepicker-toggle [for]="coverFromDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #coverFromDatepicker></mat-datepicker>
                    <div class="invalid-feedback"
                        *ngIf="(f.inception_date.touched || submitted) && f.inception_date.errors?.required">
                        Cover from is required.
                    </div> 
                    <mat-error class="invalid-feedback" *ngIf="f.inception_date.hasError('matDatepickerMin')">Please select the valid date.</mat-error>
                    
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group w-100"> 
                    <input type="text" required name="cover_to" formControlName="cover_to" placeholder="Cover To"
                        class="form-control form-control-sm datepicker" matInput (focus)="coverToDatepicker.open()"
                        [matDatepicker]="coverToDatepicker" disabled
                        [ngClass]="{ 'is-invalid': (f.cover_to.touched || submitted) && f.cover_to.errors?.required }">
                    <mat-datepicker-toggle [for]="coverToDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #coverToDatepicker></mat-datepicker>
                    <div class="invalid-feedback"
                        *ngIf="(f.cover_to.touched || submitted) && f.cover_to.errors?.required">
                        Cover to is required.
                    </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="!isBroker">
                <label><u>Broker Details</u></label>
                    <mat-form-field style="width: 93%">
                        <mat-select formControlName="company_id" class="form-control form-control-sm"
                            name="company_id" 
                            [ngClass]="{ 'is-invalid': (f.company_id.touched || submitted) && f.company_id.errors?.required  }">
                            <mat-option [value]="">Select Broker</mat-option>
                            <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                {{brk.name}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(f.company_id.touched || submitted) && f.company_id.errors?.required" class="invalid-feedback">
                            Please select a broker.
                        </div>
                    </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="quote_type == 3">
            <div class="col-md-6">
                <div class="form-group w-100">
                    <label>MTA Effective Date</label><br>
                    <input type="text" required name="effective_date" formControlName="effective_date" placeholder="MTA Effective Date"
                        class="form-control form-control-sm datepicker" matInput (focus)="effectiveDateDatepicker.open()"
                        [matDatepicker]="effectiveDateDatepicker" [min]="f.inception_date.value" [max]="f.cover_to.value"
                        [ngClass]="{ 'is-invalid': (f.effective_date.touched || submitted) && f.effective_date.errors?.required }">
                    <mat-datepicker-toggle [for]="effectiveDateDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #effectiveDateDatepicker></mat-datepicker>
                    <div class="invalid-feedback"
                        *ngIf="(f.effective_date.touched || submitted) && f.effective_date.errors?.required">
                        Effective date is required.
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="form_sections" formGroupName="proposer">
        <h4><u>Company Details</u></h4>
        <p>Is the proposer a Company, an Individual or in Joint Names?</p>
        <mat-radio-group class="row" aria-label="Select an option" name="proposer_type" formControlName="proposer_type"
            required (change)="onProposerChange($event)">
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button value="company">Company</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button value="individual">Individual</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button value="joint">Joint Names</mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
        <div class="invalid-feedback" style="font-size: 13px;"
            *ngIf="(proposerControls.proposer_type.touched || submitted) && proposerControls.proposer_type.errors?.required">
            Please select the proposer type.
        </div>

        <!-- Company Details  -->
        <div
            *ngIf="proposerControls.proposer_type.value == 'company' || proposerControls.proposer_type.value == 'joint'">
            <div *ngIf="proposerControls.proposer_type.value == 'company'">
                <h6 class="mt-3">Is this client a Micro-enterprise?</h6>
                <mat-radio-group class="row" aria-label="Select an option" name="micro_enterprise"
                    formControlName="micro_enterprise" required autocomplete="off">
                    <div class="col-md-4">
                        <div class="quote_radio w-90">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio w-90">
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
                <div class="invalid-feedback" style="font-size: 13px;"
                    *ngIf="(proposerControls.micro_enterprise.touched || submitted) && proposerControls.micro_enterprise.errors?.required">
                    Please select the proposer type.
                </div>

                <h6 class="mt-3">Company Details</h6>
            </div>
            <h6 class="mt-3" *ngIf="proposerControls.proposer_type.value == 'joint'">Joint Names</h6>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="company_name" placeholder="{{ (proposerControls.proposer_type.value == 'joint')?'Names':'Company name' }}"
                            class="form-control form-control-sm" formControlName="company_name" required
                            [ngClass]="{ 'is-invalid': (proposerControls.company_name.touched || submitted) && proposerControls.company_name.errors?.required }">
                        <div class="invalid-feedback"
                            *ngIf="(proposerControls.company_name.touched || submitted) && proposerControls.company_name.errors?.required">
                            {{ (proposerControls.proposer_type.value == 'joint')?'Names field':'Company name' }} is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="trading_name" placeholder="Trading Name"
                            class="form-control form-control-sm" formControlName="trading_name">
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="proposerControls.proposer_type.value == 'company'">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="year" placeholder="Year Company Established"
                            class="form-control form-control-sm" formControlName="year" required maxlength="4"
                            appNumbers
                            [ngClass]="{ 'is-invalid': (proposerControls.year.touched || submitted) && proposerControls.year.errors?.required }">
                        <div class="invalid-feedback"
                            *ngIf="(proposerControls.year.touched || submitted) && proposerControls.year.errors?.required">
                            Please enter the year.
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <!------------- INDIVIDUAL PERSONAL DETAILS  ------------------>
        <div class="mt-3" *ngIf="proposerControls.proposer_type.value == 'individual'">
            <h6>Individual details</h6>
            <div class="row">
                <div class="col-md-4" *ngIf="staticData">
                    <mat-form-field class="w-100">
                        <mat-label>Title</mat-label>
                        <mat-select formControlName="title" class="form-control form-control-sm" name="title" required
                            [ngClass]="{ 'is-invalid': (proposerControls.title.touched || submitted) && proposerControls.title.errors?.required }">
                            <mat-option *ngFor="let title of staticData.title_options" [value]="title">
                                {{ title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="invalid-feedback"
                        *ngIf="(proposerControls.title.touched || submitted) && proposerControls.title.errors?.required">
                        Title is required.
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mt-1">
                        <input type="text" name="first_name" placeholder="First Name"
                            class="form-control form-control-sm" formControlName="first_name" required
                            [ngClass]="{ 'is-invalid': (proposerControls.first_name.touched || submitted) && proposerControls.first_name.errors?.required }">
                        <div class="invalid-feedback"
                            *ngIf="(proposerControls.first_name.touched || submitted) && proposerControls.first_name.errors?.required">
                            First name is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" name="last_name" placeholder="Last Name" class="form-control form-control-sm"
                            formControlName="last_name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group w-100 mt-2">
                        <input type="text" required name="dob" formControlName="dob" placeholder="Date Of Birth"
                            class="form-control form-control-sm datepicker" matInput (focus)="dobDatepicker.open()"
                            [matDatepicker]="dobDatepicker" [max]="maxDOB"
                            [ngClass]="{ 'is-invalid': (proposerControls.dob.touched || submitted) && proposerControls.dob.errors?.required }">
                        <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #dobDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(proposerControls.dob.touched || submitted) && proposerControls.dob.errors?.required">
                            DOB is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-4"  *ngIf="staticData">
                    <mat-form-field class="w-100">
                        <mat-label>Type of licence</mat-label>
                        <mat-select formControlName="licence_type" class="form-control form-control-sm"
                            name="licence_type">
                            <mat-option *ngFor="let lic of staticData.licence_types" [value]="lic">
                                {{ lic }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col-md-4">
                    <div class="form-group w-100 mt-1">
                        <input type="text" required name="licence_length" formControlName="licence_length"
                            placeholder="Length licence held" class="form-control form-control-sm datepicker" matInput
                            (focus)="licenceLengthDatepicker.open()" [matDatepicker]="licenceLengthDatepicker">
                        <mat-datepicker-toggle [for]="licenceLengthDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #licenceLengthDatepicker></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="proposerControls.proposer_type.value">
            <h6 class="mt-3">Correspondence Address</h6>
            <div formGroupName="address">
                <div class="row"> 
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="postcode" placeholder="Postcode"
                                class="form-control form-control-sm"
                                (keyup)="proposerAddressControls.postcode.errors?'':postcodeLookup($event)"
                                pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                formControlName="postcode"
                                [ngClass]="{ 'is-invalid': proposerAddressControls.postcode.invalid }" [matAutocomplete]="auto"
                                appUpperCase>
                            <div *ngIf="proposerAddressControls.postcode.errors?.pattern" class="invalid-feedback">
                                Invalid postcode.
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(proposerAddressControls.postcode.touched || submitted) && proposerAddressControls.postcode.errors?.required">
                                Please enter the postcode.
                            </div>
                            <div>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <div
                                        *ngIf="!proposerAddressControls.postcode.errors && proposerAddressControls.postcode.value != ''">
                                        <mat-option [value]="proposerAddressControls.postcode.value"
                                            (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                            {{adrs.formatted_address.join(', ')}}</mat-option>
                                    </div>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="line1" placeholder="Address Line 1"
                                class="form-control form-control-sm" formControlName="line1" required
                                [ngClass]="{ 'is-invalid': (proposerAddressControls.line1.touched || submitted) && proposerAddressControls.line1.errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(proposerAddressControls.line1.touched || submitted) && proposerAddressControls.line1.errors?.required">
                                Please enter the address line 1.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="line2" placeholder="Address Line 2"
                                class="form-control form-control-sm" formControlName="line2">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="line3" placeholder="Address Line 3"
                                class="form-control form-control-sm" formControlName="line3">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="county" placeholder="County" class="form-control form-control-sm"
                                formControlName="county" required
                                [ngClass]="{ 'is-invalid': (proposerAddressControls.county.touched || submitted) && proposerAddressControls.county.errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(proposerAddressControls.county.touched || submitted) && proposerAddressControls.county.errors?.required">
                                Please enter the county.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!------------- CONVICTIONS  ------------------>
        <div class="mt-3" *ngIf="proposerControls.proposer_type.value == 'individual'">
            <h6>Has the proposer had any unspent major and/or more than 2 minor motoring convictions?</h6>
            <mat-radio-group class="row" aria-label="Select an option" name="have_convictions"
                formControlName="have_convictions" required (change)="onHaveConventionsChange($event)">
                <div class="col-md-4">
                    <div class="quote_radio w-90">
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="quote_radio w-90">
                        <mat-radio-button [value]="false">No</mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
            <div class="invalid-feedback" style="font-size: 13px;"
                *ngIf="(proposerControls.have_convictions.touched || submitted) && proposerControls.have_convictions.errors?.required">
                Please select the proposer type.
            </div>
            
            <div formArrayName="convictions" *ngIf="staticData && proposerControls.have_convictions.value" class="pl-4 pr-4 pt-0 pb-4 green_border_panel mt-3">
                <div *ngFor="let c of f.proposer['controls'].convictions['controls']; let i=index">
                    <div formGroupName="{{i}}" class="mt-3">
                        <u>Conviction {{ i+1 }}</u> <a (click)="removeConvictions(i)" class="btn btn-danger btn-sm p-1 ml-3"
                            *ngIf="i > 0"><i class="mdi mdi-delete"></i></a>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Conviction code</mat-label>
                                    <mat-select formControlName="code" class="form-control form-control-sm"
                                        name="code" required
                                        [ngClass]="{ 'is-invalid': (c.get('code').touched || submitted) && c.get('code').errors?.required }">
                                        <mat-option *ngFor="let type of staticData.conviction_types"
                                            [value]="type.code">
                                            {{ type.code }}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(c.get('code').touched || submitted) && c.get('code').errors?.required">
                                        Please select an option.
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group w-100 mt-2">
                                    <input type="text" required name="date" formControlName="date"
                                        placeholder="Date of the conviction"
                                        class="form-control form-control-sm datepicker" matInput
                                        (focus)="cdDatepicker.open()" [matDatepicker]="cdDatepicker"
                                        [ngClass]="{ 'is-invalid': (c.get('date').touched || submitted) && c.get('date').errors?.required }">
                                    <mat-datepicker-toggle [for]="cdDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #cdDatepicker></mat-datepicker>
                                    <div class="invalid-feedback"
                                        *ngIf="(c.get('date').touched || submitted) && c.get('date').errors?.required">
                                        Date is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group w-100 mt-2">
                                    <input type="number" required name="number_of_points"
                                        formControlName="points" placeholder="Number of Points"
                                        class="form-control form-control-sm" matInput
                                        [ngClass]="{ 'is-invalid': (c.get('points').touched || submitted) && c.get('points').errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(c.get('points').touched || submitted) && c.get('points').errors?.required">
                                        Number of points are required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group w-100 mt-2">
                                    <input type="number" name="months_banned" formControlName="banned"
                                        placeholder="Enter number of months banned" class="form-control form-control-sm"
                                        matInput>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group w-100 mt-2">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" required name="fine" formControlName="fine"
                                            placeholder="Enter fine amount" class="form-control form-control-sm"
                                            matInput
                                            [ngClass]="{ 'is-invalid': (c.get('fine').touched || submitted) && c.get('fine').errors?.required }">
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="(c.get('fine').touched || submitted) && c.get('fine').errors?.required">
                                        Amount is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a (click)="addConvictions()" class="btn btn-success btn-sm">Add Convictions</a>
            </div>

        </div>

        
    </div>

    <div class="form_sections">
        <h6>Is the expiring Horsebox motor policy fleet experience of NCD rated?</h6>
        <mat-radio-group class="row" aria-label="Select an option" name="rating_type" formControlName="rating_type"
        (change)="onRatingTypeChange($event)">
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button value="fleet">Fleet Experience</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button value="ncd">NCD Rated</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button value="none">No Previous Agricultural Insurance</mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
    </div>

    <!--------------- CLIAMS ------------->
    <div *ngIf="proposerControls.proposer_type.value && !hideClaims" class="mt-3 form_sections" formGroupName="proposer">
        <h6 *ngIf="proposerControls.proposer_type.value == 'company'">Have you had any claims in the last 3 years?
        </h6>
        <h6 *ngIf="proposerControls.proposer_type.value == 'individual'">During the past 3 years, has the proposer
            been involved in a motor accident or made a motor insurance claim?</h6>
        <h6 *ngIf="proposerControls.proposer_type.value == 'joint'">Have you had any claims in the last 3 years?
        </h6>
        <mat-radio-group class="row" aria-label="Select an option" name="have_claims" formControlName="have_claims"
            required (change)="onHaveClaimsChange($event)">
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="quote_radio w-90">
                    <mat-radio-button [value]="false">No</mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
        <div class="invalid-feedback" style="font-size: 13px;"
            *ngIf="(proposerControls.have_claims.touched || submitted) && proposerControls.have_claims.errors?.required">
            Please select the option.
        </div>

        <div formArrayName="claims" *ngIf="proposerControls.have_claims.value == 1" class="pl-4 pr-4 pt-0 pb-4 green_border_panel mt-3">
            <div *ngFor="let c of f.proposer['controls'].claims['controls']; let i=index">
                <div formGroupName="{{i}}" class="mt-3">
                    <u>Claim {{ i+1 }}</u> <a (click)="removeClaims(i)" class="btn btn-danger btn-sm p-1 ml-3" *ngIf="i > 0"><i
                            class="mdi mdi-delete"></i></a>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group w-100 mt-2">
                                <input type="text" required name="date" formControlName="date"
                                    placeholder="What was the date of the claim?"
                                    class="form-control form-control-sm datepicker" matInput
                                    (focus)="claimDatepicker.open()" [matDatepicker]="claimDatepicker"
                                    [ngClass]="{ 'is-invalid': (c.get('date').touched || submitted) && c.get('date').errors?.required }">
                                <mat-datepicker-toggle [for]="claimDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #claimDatepicker></mat-datepicker>
                                <div class="invalid-feedback"
                                    *ngIf="(c.get('date').touched || submitted) && c.get('date').errors?.required">
                                    Date is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="staticData">
                            <mat-form-field class="w-100">
                                <mat-label>Type of incident</mat-label>
                                <mat-select formControlName="type" class="form-control form-control-sm" name="type"
                                    required
                                    [ngClass]="{ 'is-invalid': (c.get('type').touched || submitted) && c.get('type').errors?.required }">
                                    <mat-option *ngFor="let claim_type of staticData.claim_types"
                                        [value]="claim_type">
                                        {{ claim_type }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="invalid-feedback"
                                *ngIf="(c.get('type').touched || submitted) && c.get('type').errors?.required">
                                Type is required.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group w-100 mt-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="number" required name="ad_cost" formControlName="ad_cost"
                                        placeholder="AD claim cost" class="form-control form-control-sm" matInput
                                        [ngClass]="{ 'is-invalid': (c.get('ad_cost').touched || submitted) && c.get('ad_cost').errors?.required }">
                                </div>
                                <div class="invalid-feedback"
                                    *ngIf="(c.get('ad_cost').touched || submitted) && c.get('ad_cost').errors?.required">
                                    Amount is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group w-100 mt-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="number" required name="tp_cost" formControlName="tp_cost"
                                        placeholder="TP claim cost" class="form-control form-control-sm" matInput
                                        [ngClass]="{ 'is-invalid': (c.get('tp_cost').touched || submitted) && c.get('tp_cost').errors?.required }">
                                </div>
                                <div class="invalid-feedback"
                                    *ngIf="(c.get('tp_cost').touched || submitted) && c.get('tp_cost').errors?.required">
                                    Amount is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group w-100 mt-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="number" required name="total_cost"
                                        [value]="c.get('ad_cost').value + c.get('tp_cost').value"
                                        placeholder="Total claim cost" class="form-control form-control-sm" matInput
                                        readonly>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Was the driver at fault?</label>
                            <mat-radio-group class="row" aria-label="Select an option" name="fault_{{i}}"
                                formControlName="fault"> 
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]=true>Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]=false>No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <label>Is the claim still open?</label>
                            <mat-radio-group class="row" aria-label="Select an option" name="open_{{i}}"
                                formControlName="open">
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]=true>Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]=false>No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <a (click)="addClaims()" class="btn btn-success btn-sm mt-3">Add Claim</a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right"><button class="btn btn-success btn-sm">Proceed</button></div>
    </div>
    

</form>
<!-- <p>
    Form is {{frmFields.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{frmFields.value | json}}
            </pre> -->
