import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, NgForm, FormArray, FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { AmlineService } from '../../../quote/amline/amline.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from 'src/app/quote/quote.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-am-policy',
  templateUrl: './am-policy.component.html',
  styleUrls: ['./am-policy.component.css']
})
export class AmPolicyComponent implements OnInit {
  staticData;
  id = this.route.snapshot.params.quote_id;
  constructor(private as: AmlineService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal,
    private quoteService: QuoteService, private formBuilder: FormBuilder, private el: ElementRef) { }

  minCacellationDate = new Date();
  maxCacellationDate = new Date();
  ngOnInit(): void {
    this.getAccessToken();
    let minDate = new Date().setDate(new Date().getDate());
    this.minCacellationDate = new Date(minDate);
    let maxDate = new Date().setDate(new Date().getDate() + 30);
    this.maxCacellationDate = new Date(maxDate);

  }
  authDetails: string = '';
  getAccessToken() {
    let data = {
      'client_id': environment.AMLINE_CLIENT_ID,
      'client_secret': environment.AMLINE_CLIENT_SECRET,
      'grant_type': environment.AMLINE_GRANT_TYPE,
      'scope': environment.AMLINE_SCOPE,
    }
    this.as.getAccessToken(data)
      .subscribe(
        (response: any) => {
          if (response.access_token) {
            this.authDetails = response;
            this.getStaticData(response);
            this.getQuote(response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // GET STATIC DATA
  getStaticData(response) {
    this.as.getStaticData(response)
      .subscribe(
        (response: any) => {
          if (response.meta) {
            this.staticData = response.meta;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  // GET QUOTE DETAILS
  quoteDetails;
  policyID: string = '';
  getQuote(authDetails) {
    this.as.getAmlineData(this.route.snapshot.params.quote_id)
      .subscribe(
        (response: any) => {
          this.policyID = response.result.quote_data.quote_versions[0].amlin_data.data.id;
          this.quoteDetails = response.result.quote_data.quote_versions[0].amlin_data.data.attributes;
          this.quoteDetails.insurance_status_id = response.result.quote_data.insurance_status_id;
          this.quoteDetails.id = response.result.quote_data.quote_versions[0].id;
          this.quoteDetails.broker_id = response.result.quote_data.quote_versions[0].broker_id;
          this.quoteDetails.amlin_policy_reference = response.result.quote_data.quote_versions[0].amlin_policy_reference;
          this.quoteDetails.peliwica_fee = response.result.quote_data.quote_versions[0].financial_details.fees;
          this.quoteDetails.notes = response.result.quote_data.quote_versions[0].notes;
          this.getQuoteNotes();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Issue Quote
  issueQuotePopup(issueQuoteModal) {
    this.modalService.open(issueQuoteModal, { centered: true });
  }
  issueQuote() {
    let data = {
      "amlin_quote_id": this.route.snapshot.params.quote_id
    };


    this.as.issueQuote(data)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.modalService.dismissAll();
            this.router.navigateByUrl("/policy-list");
          };
        });
  }

  /*
  * Get Notes
  */
  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  getQuoteNotes() {
    this.quoteService.getNotes(this.quoteDetails.id)
      .subscribe(
        (response: any) => {
          let impNotes = response.result.filter(x => x.is_important == 1);
          this.totalImpNotes = impNotes.length;
          if (impNotes.length > 0) {
            this.hasImportantNotes = true;
          }
        });
  }

  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  /*
  * Convert To MTA
  */
  policyActions(type) {
    this.router.navigateByUrl("/amline/quote/" + this.id + '/' + type);
  }

  /*
  * Cancel Policy Popup
  */
  submitted: boolean = false;
  cancellation_date;
  cancellation_reason;
  refund_amount;
  cancelPolicy(cancelModal: any) {
    this.modalService.open(cancelModal, { centered: true });
  }

  errorMsg: string = '';
  getReturnPremium() {
    this.errorMsg = null;
    if (this.cancellation_date && this.cancellation_reason) {
      this.refund_amount = null;
      let data = {
        'meta': {
          "effective_date": formatDate(this.cancellation_date, 'yyyy-MM-dd', 'en-US'),
          "reason": this.cancellation_reason,
        }
      };


      this.as.getReturnPremium(data, this.policyID, this.authDetails)
        .subscribe(
          (res: any) => {
            if (res) {
              if (res.meta) {
                this.refund_amount = res.meta.return_premium;
              } 
            }
          }, err => {
            if (err.error.errors && err.error.errors.length > 0) {
              this.errorMsg = err.error.errors[0].detail;
            }
          });
    }
  }

  onSubmitCancelPolicy() {
    this.submitted = true;
    let data = {
      'meta': {
        "effective_date": formatDate(this.cancellation_date, 'yyyy-MM-dd', 'en-US'),
        "reason": this.cancellation_reason,
        "return_premium": this.refund_amount,
      }
    };


    this.as.getCancelPolicy(data, this.policyID, this.authDetails)
      .subscribe(
        (res: any) => {
          if (res) {
            this.modalService.dismissAll();
            this.getQuote(this.authDetails);
          }
        }, err => {
          if (err.error.errors && err.error.errors.length > 0) {
            this.errorMsg = err.error.errors[0].detail;
          }
        });
  }
}
