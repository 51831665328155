import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MiComponent } from './dashboard/mi/mi.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CompanyComponent } from './company/company.component';
import { LayoutLoginComponent } from './_layouts/layout-login/layout-login.component';
import { LayoutInnerpagesComponent } from './_layouts/layout-innerpages/layout-innerpages.component';
import { CompanyUpsertComponent } from './company/company-upsert.component';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { QuoteComponent } from './quote/quote.component';
import { QuoteListComponent } from './quote/quote-list/quote-list.component'; 
import { QuoteDetailsComponent } from './quote/quote-details/quote-details/quote-details.component'; 
import { PolicyComponent } from './policy/policy.component'; 
import { PolicyListComponent } from './policy/policy-list/policy-list.component'; 
import { BordereauComponent } from './bordereau/bordereau.component'; 
import {LoginGuard} from './auth/auth.guard';
import {AdminAlQuoteComponent} from './quote/alpaca/al-quote/al-quote.component';

import { AlQuoteDetailsComponent } from './quote/alpaca/quote-details/quote-details/quote-details.component';

import { AlPolicyDetailsComponent } from './policy/alpaca/policy-details/policy-details.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { QuoteEmailViewComponent } from './quote-email/quote-email-view/quote-email-view.component';
import { BordereauLiveComponent } from './bordereau/bordereau-live/bordereau-live.component';
import { BordereauLapseComponent } from './bordereau/bordereau-lapse/bordereau-lapse.component';
import { QuotePreQualifierComponent } from './quote-pre-qualifier/quote-pre-qualifier.component';

import { LivestockComponent } from './quote/livestock/livestock.component';
import { LvQuoteDetailsComponent } from './quote/livestock/quote-details/quote-details/quote-details.component';

import { LvPaymentConfirmationComponent } from './quote/livestock/lv-payment-confirmation/lv-payment-confirmation.component';
import { LivestockViewComponent } from './quote-view/livestock-view/livestock-view.component';
import {UnauthorizedAccessComponent} from './unauthorized-access/unauthorized-access.component';

import { AllianzComponent } from './quote/allianz-equine/allianz.component';
import { AllianzQuoteDetailsComponent } from './quote/allianz-equine/quote-details/quote-details/quote-details.component';

import { AllianzPolicyDetailsComponent } from './policy/allianz-equine/policy-details/policy-details.component';
import { AjgQuoteComponent } from './quote/ajg/ajg-quote.component';
import { AjgQuoteViewComponent } from './quote/ajg/quote-view/ajg-quote-view.component';
import { PolicyViewComponent } from './policy/policy-view/policy-view.component';

import { AmlineComponent } from './quote/amline/amline.component';
import { AmQuoteViewComponent } from './quote/amline/am-quote-view/am-quote-view.component';
import { AmPolicyComponent } from './policy/amlin/am-policy/am-policy.component'; 


const appRoutes: Routes = [  
  {
    path: '', component: LayoutLoginComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent }
    ]
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: '', component: LayoutInnerpagesComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent,canActivate: [LoginGuard]  },
      { path: 'dashboard-mi/:frequency', component: MiComponent,canActivate: [LoginGuard]  },
      { path: 'company', component: CompanyComponent, canActivate: [LoginGuard] },
      { path: 'company-manage', component: CompanyUpsertComponent, canActivate: [LoginGuard]},
      { path: 'company-manage/:id', component: CompanyUpsertComponent , canActivate: [LoginGuard]},
      { path: 'user/:company_id/:id', component: UserComponent , canActivate: [LoginGuard]},
      { path: 'user-list/:company_id', component: UserListComponent, canActivate: [LoginGuard] },
      { path: 'user/:company_id', component: UserComponent, canActivate: [LoginGuard] },
      { path: 'pre-qualifier/:type', component: QuotePreQualifierComponent, canActivate: [LoginGuard] },
      { path: 'pre-qualifier/:type/:id', component: QuotePreQualifierComponent, canActivate: [LoginGuard] },
      { path: 'pre-qualifier/:type/:id/:quote_type', component: QuotePreQualifierComponent, canActivate: [LoginGuard] },
      { path: 'quote', component: QuoteComponent, canActivate: [LoginGuard] },
      { path: 'quote/:id', component: QuoteComponent, canActivate: [LoginGuard] },
      { path: 'quote/:id/:quote_type', component: QuoteComponent, canActivate: [LoginGuard] },
      { path: 'quote-list', component: QuoteListComponent, canActivate: [LoginGuard] },
      { path: 'quote-details/:quote_id', component: QuoteDetailsComponent, canActivate: [LoginGuard] },
      { path: 'policy-list', component: PolicyListComponent, canActivate: [LoginGuard] },
      { path: 'policy/:quote_id', component: PolicyComponent, canActivate: [LoginGuard] },
      { path: 'bordereau', component: BordereauComponent, canActivate: [LoginGuard] },
      { path: 'live-policy-report', component: BordereauLiveComponent, canActivate: [LoginGuard] },
      { path: 'lapse-report', component: BordereauLapseComponent, canActivate: [LoginGuard] }
    ]
  },
  {
    path: 'alpaca-admin', component: LayoutInnerpagesComponent,
    children: [
      { path: 'quote', component: AdminAlQuoteComponent },
      { path: 'quote/:quote_id', component: AdminAlQuoteComponent },
      { path: 'quote/:quote_id/:quote_type', component: AdminAlQuoteComponent },
      { path: 'quote-details/:quote_id', component: AlQuoteDetailsComponent },
      { path: 'policy/:quote_id', component: AlPolicyDetailsComponent }
    ]
  },
  {
    path: 'livestock', component: LayoutInnerpagesComponent,
    children: [
      { path: 'quote', component: LivestockComponent },
      { path: 'quote/:id', component: LivestockComponent },
      { path: 'quote/:id/:quote_type', component: LivestockComponent },
      { path: 'quote-details/:quote_id', component: LivestockViewComponent },
      { path: 'policy/:id', component: LivestockViewComponent },
      { path: 'lvpayment', component: LvPaymentConfirmationComponent },
    ]
  },
  {
    path: 'allianz-equine', component: LayoutInnerpagesComponent,
    children: [
      { path: 'quote', component: AllianzComponent },
      { path: 'quote/:id', component: AllianzComponent },
      { path: 'quote/:id/:quote_type', component: AllianzComponent },
      { path: 'quote-details/:quote_id', component: AllianzQuoteDetailsComponent },
      { path: 'policy/:quote_id', component: AllianzPolicyDetailsComponent },
    ]
  },
  {
    path: 'ajg', component: LayoutInnerpagesComponent,
    children: [
      { path: 'quote', component: AjgQuoteComponent },
      { path: 'quote/:id', component: AjgQuoteComponent },
      { path: 'quote/:id/:quote_type', component: AjgQuoteComponent },
      { path: 'quote-details/:quote_id', component: AjgQuoteViewComponent },
      { path: 'policy/:quote_id', component: PolicyViewComponent },
    ]
  },
  {
    path: 'amline', component: LayoutInnerpagesComponent,
    children: [
      { path: 'quote', component: AmlineComponent },
      { path: 'quote/:id', component: AmlineComponent },
      { path: 'quote/:id/:quote_type', component: AmlineComponent },
      { path: 'quote-details/:quote_id', component: AmQuoteViewComponent },
      { path: 'policy/:quote_id', component: AmPolicyComponent },
    ]
  },
  {
    path: '', component: LayoutInnerpagesComponent,
    children: [
      { path: 'email-view/:id', component: QuoteEmailViewComponent },
      { path: 'unauthorized', component: UnauthorizedAccessComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  },
  
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
