<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Quote: {{(quoteDetails)?quoteDetails.reference:''}} 
                <span class="badge badge-danger text-white text-capitalize">{{(quoteDetails)?quoteDetails.status:''}}</span>
            </h3>
        </div> 
        <div class="col-md-6 text-right" *ngIf="quoteDetails"> 
            <span *ngIf="quoteDetails.status != 'referred' && quoteDetails.status != 'issued' && quoteDetails.status != 'declined'">
                <a [routerLink]="['/amline/quote', id]" class="btn btn-danger btn-sm">Edit</a>
            </span>
            <span *ngIf="quoteDetails.status == 'quote' || quoteDetails.status == 'referral approved' || quoteDetails.status == 'renewal invited'">
                <button (click)="issueQuotePopup(issueQuoteModal)" class="btn btn-dark btn-sm ml-2">Issue Quote</button>
            </span>
        </div>
    </div>
</div>
<mat-tab-group class="float-left w-100" [(selectedIndex)]="selectedIndex">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-am-overview [quoteDetails]="quoteDetails"></app-am-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-am-full-details [quoteDetails]="quoteDetails"></app-am-full-details>
        </div>
    </mat-tab>
    <mat-tab *ngIf="quoteDetails && quoteDetails.application.fleet_experiences && quoteDetails.application.fleet_experiences.length > 0">
        <ng-template mat-tab-label>
            Fleet Experiences
        </ng-template>
        <div class="card-body">
            <app-am-fleet-experiences-view [quoteDetails]="quoteDetails"></app-am-fleet-experiences-view>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Drivers
        </ng-template>
        <div class="card-body">
            <app-am-drivers-view [quoteDetails]="quoteDetails"></app-am-drivers-view>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Financials
        </ng-template>
        <div class="card-body">
            <app-am-financials [quoteDetails]="quoteDetails"></app-am-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-am-documents [quote]="quoteDetails"></app-am-documents>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000" title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-quote-notes [quote]="quoteDetails" (hasImportantNotes)="onhasImportantNotesChange($event)"></app-quote-notes>
        </div>
    </mat-tab>
</mat-tab-group>
<ng-template #issueQuoteModal let-modal>
    <div class="modal-body">
        <p>Do you want to issue the quote?</p>        
    </div>
    <div class="modal-footer"> 
        <button type="button" class="btn btn-default btn-sm" (click)="modal.close()">No</button>
        <button type="button" class="btn btn-success btn-sm" (click)="issueQuote()">Yes</button>        
    </div>
</ng-template>