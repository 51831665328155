<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit()">
<div formArrayName="claims" class="pt-0">
    <div *ngFor="let c of claimsArr.controls; let i=index">
        <div formGroupName="{{i}}" class="mt-3">
            <u class="text-white">Claim {{ i+1 }}</u> <a (click)="removeClaims(i)" class="btn btn-danger btn-sm p-1 ml-3" *ngIf="i > 0"><i
                    class="mdi mdi-delete"></i></a>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group w-100 mt-2">
                        <input type="text" required name="date" formControlName="date"
                            placeholder="What was the date of the claim?"
                            class="form-control form-control-sm datepicker" matInput
                            (focus)="claimDatepicker.open()" [matDatepicker]="claimDatepicker"
                            [ngClass]="{ 'is-invalid': (c.get('date').touched || submitted) && c.get('date').errors?.required }">
                        <mat-datepicker-toggle [for]="claimDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #claimDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(c.get('date').touched || submitted) && c.get('date').errors?.required">
                            Date is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="staticData">
                    <mat-form-field class="w-100">
                        <mat-label>Type of incident</mat-label>
                        <mat-select formControlName="type" class="form-control form-control-sm" name="type"
                            required
                            [ngClass]="{ 'is-invalid': (c.get('type').touched || submitted) && c.get('type').errors?.required }">
                            <mat-option *ngFor="let claim_type of staticData.claim_types"
                                [value]="claim_type">
                                {{ claim_type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="invalid-feedback"
                        *ngIf="(c.get('type').touched || submitted) && c.get('type').errors?.required">
                        Type is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group w-100 mt-2">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" required name="ad_cost" formControlName="ad_cost"
                                placeholder="AD claim cost" class="form-control form-control-sm" matInput
                                [ngClass]="{ 'is-invalid': (c.get('ad_cost').touched || submitted) && c.get('ad_cost').errors?.required }">
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="(c.get('ad_cost').touched || submitted) && c.get('ad_cost').errors?.required">
                            Amount is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group w-100 mt-2">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" required name="tp_cost" formControlName="tp_cost"
                                placeholder="TP claim cost" class="form-control form-control-sm" matInput
                                [ngClass]="{ 'is-invalid': (c.get('tp_cost').touched || submitted) && c.get('tp_cost').errors?.required }">
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="(c.get('tp_cost').touched || submitted) && c.get('tp_cost').errors?.required">
                            Amount is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group w-100 mt-2">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" required name="total_cost"
                                [value]="c.get('ad_cost').value + c.get('tp_cost').value"
                                placeholder="Total claim cost" class="form-control form-control-sm" matInput
                                readonly>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="text-white">Was the driver at fault?</label>
                    <mat-radio-group class="row" aria-label="Select an option" name="fault_{{i}}"
                        formControlName="fault"> 
                        <div class="col-md-4">
                            <div class="quote_radio w-90">
                                <mat-radio-button value="true">Yes</mat-radio-button>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="quote_radio w-90">
                                <mat-radio-button value="false">No</mat-radio-button>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-6">
                    <label class="text-white">Is the claim still open?</label>
                    <mat-radio-group class="row" aria-label="Select an option" name="open_{{i}}"
                        formControlName="open">
                        <div class="col-md-4">
                            <div class="quote_radio w-90">
                                <mat-radio-button value="true">Yes</mat-radio-button>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="quote_radio w-90">
                                <mat-radio-button value="false">No</mat-radio-button>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <a (click)="addClaims()" class="btn btn-success btn-sm mt-3 mb-3" *ngIf="is_claims_selected">Add Claim</a>
</div>
</form>